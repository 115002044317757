<template lang="pug">
.product-card(v-if="product")
  .product-card__cont.cont
    .product-card__crumbs
      crumbs(:slug="product.category_slug", :linkName="product.category_name")
      schema-org-crumbs(:slug="product.category_slug", :linkName="product.category_name")
    .product-card__area
      .product-card__picture-box
        template(v-if="product.images")
          picture.product-card__picture(v-for="(image, i) in product.images", :key="i")
            source(:srcset="`${image.w360} 1x, ${image.w720} 2x`", type="image/webp", media="(max-width: 360px)")
            source(:srcset="`${image.w520} 1x, ${image.w1040} 2x`", type="image/webp", media="(max-width: 520px)")
            source(:srcset="`${image.w468} 1x, ${image.w936} 2x`", type="image/webp", media="(max-width: 700px)")
            source(:srcset="`${image.w280} 1x, ${image.w560} 2x`", type="image/webp", media="(max-width: 950px)")
            source(:srcset="`${image.w400} 1x, ${image.w800} 2x`", type="image/webp", media="(max-width: 1240px)")
            source(:srcset="`${image.w560} 1x, ${image.w840} 1.5x`", type="image/webp")
            source(:srcset="`${image.j560} 1x, ${image.j840} 1.5x`")
            img.product-card__image(:src="image.j560", :alt="product.name", :title="product.name", loading="lazy", width="560", height="560")
        picture.product-card__picture(v-else)
          img.product-card__image(src="/images/logo.svg", :alt="product.name", :title="product.name", width="560", height="560")
      .product-card__info
        h1.product-card__title.title {{ product.name }}
          |
          a.link_white-transparent.ff-garamond(v-if="product.backend_link", :href="product.backend_link", target="_blank") #
        .product-card__price-box
          span.product-card__actual-price(v-if="product.price_current") {{ product.price_current.toLocaleString() }}
          span.product-card__old-price(v-if="product.price_old") {{ product.price_old.toLocaleString() }}
          button.product-card__favorites.product-card__favorites_mobile(type="button", @click="toggleFavorites", :class="{'product-card__favorites_active': isFavorite}")
        .product-card__box
          .product-card__amount
            button.product-card__amount-button.product__amount-button_minus(:disabled="amount <= 1", @click="decreaseAmount")
            input.product-card__amount-input(type="text", v-model="amount")
            button.product-card__amount-button.product__amount-button_plus(@click="increaseAmount")
          button.product-card__cart.button-white(type="button", @click="addProduct", :disabled="disabled") {{ isAdd }}
          button.product-card__favorites(type="button", @click="toggleFavorites", :class="{'product-card__favorites_active': isFavorite}")
        p.product-card__description(v-if="product.description") {{ product.description }}
        .product-card__offer(v-if="product.offer && product.offer.length")
          ul.product-card__offer-list
            li.product-card__offer-item(v-for="(item, i) in product.offer" :key="i")
              button.product-card__offer-button(
                  type="button",
                  :class="{'product-card__offer-button_active': item.type_id === offerId }",
                  @click ="changeOffer(item)"
                ) {{item.typeName}} {{item.price.toLocaleString()}} ₽
                span.product-card__offer-old-price(v-if="item.price_old") {{item.price_old.toLocaleString()}} ₽
        table.product-card__table(v-if="product.weight || product.composition")
          tr(v-if="product.weight")
            td.product-card__table-name Масса:
            td.product-card__table-value {{ product.weight }} г.
          tr(v-if="product.composition")
            td.product-card__table-name Состав:
            td.product-card__table-value {{ product.composition }}
    .product-card__video(v-if="product.video")
      iframe.product-card__frame(
        :src="product.video",
        frameborder='0',
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
        loading='lazy'
      )
</template>

<script>
import '@/assets/styles/components/product-card.sass'
import Crumbs from '../_tools/Crumbs'
import SchemaOrgCrumbs from '../_tools/SchemaOrgCrumbs'
import { addProduct, refreshProduct, refreshUserLazy } from '../../assets/scripts/API'

export default {
  props: {
    productStart: { type: Object }
  },
  components: { Crumbs, SchemaOrgCrumbs },
  name: 'ProductCard',
  data () {
    return {
      product: null,
      amount: 1,
      isAdded: false,
      disabled: false,
      isPlayVideo: false,
      isFavorite: false,
      offerId: ''
    }
  },
  emits: ['toggleFavorites'],
  computed: {
    isAdd () {
      return this.isAdded ? 'В корзине' : 'В корзину'
    }
  },
  watch: {
    productStart (data) {
      this.product = data
      if (data) {
        this.isFavorite = data.in_favorites
      }
      if (data.cart_count) {
        this.isAdded = true
        this.amount = data.cart_count
      } else {
        this.isAdded = false
        this.amount = 1
      }
      if (data.offer && data.offer.length) {
        this.offerId = data.offer[0].type_id
        this.product.price_current = data.offer[0].price
        this.product.price_old = data.offer[0].price_old || ''
      }
    }
  },
  methods: {
    increaseAmount () {
      this.amount++
    },
    decreaseAmount () {
      this.amount = this.amount > 1 ? this.amount-- : 1
    },
    async addProduct () {
      this.disabled = true
      setTimeout(() => { this.disabled = false }, 500)
      await addProduct(this.product.id, this.amount, this.offerId)
      this.isAdded = true
      refreshUserLazy()
      refreshProduct(this.product.slug)
    },
    toggleFavorites () {
      this.isFavorite = !this.isFavorite
      const flag = this.isFavorite
      setTimeout(() => {
        if (flag === this.isFavorite) this.$emit('toggleFavorites', flag)
      }, 500)
    },
    changeOffer (offer) {
      this.offerId = offer.type_id
      this.product.price_current = offer.price
      this.product.price_old = offer.price_old || ''
      this.isAdded = false
    }
  }
}
</script>
