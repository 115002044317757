<template lang="pug">
head-meta(
  :title-page="titlePage",
  :description="descriptionPage"
  :image-src="imageSrc"
)
template(v-if="!isNotFound")
  product-card(:product-start="product", @toggleFavorites="toggleFavorites")
  tile-line(v-if="product", title="Вам может понравиться", :products="product.similar_products")
  schema-org-product(:product="product")
the-error(v-else, text="Такого товара не существует", :route="{ name: 'Catalog', text: 'В каталог' }" )
contacts-line(:title="contactsLineTitle")
</template>

<script>
import ProductCard from '../components/product-card/ProductCard'
import TileLine from '../components/_tiles/TileLine'
import { getProduct, addInFavorites, removeFromFavorites, refreshProduct } from '../assets/scripts/API'
import HeadMeta from '../components/_layout/HeadMeta'
import SchemaOrgProduct from '../components/_layout/SchemaOrgProduct'
import ContactsLine from '../components/_layout/TheContactsLine'
import TheError from '../components/error/TheError'

export default {
  name: 'ProductPage',
  components: { TheError, ContactsLine, SchemaOrgProduct, HeadMeta, ProductCard, TileLine },
  data () {
    return {
      product: null,
      isDisabled: false,
      isNotFound: false
    }
  },
  methods: {
    async getDataPage (slug) {
      const data = await getProduct(slug)
      if (data.status === 'error') return await this.$router.push({ name: 'Error' })
      this.product = data
    },
    async toggleFavorites (flag) {
      if (flag) {
        await addInFavorites(this.product.id)
      } else {
        await removeFromFavorites(this.product.id)
      }
      this.product = await refreshProduct(this.$route.params.slug)
    }
  },
  computed: {
    titlePage () {
      if (this.isNotFound) {
        return 'Страница не найдена'
      }
      return this.product && this.product.name ? this.product.name : ''
    },
    descriptionPage () {
      return this.product && this.product.name ? `Купить ${this.product.name} от производителя, ручная работа, натуральный состав, премиальное качество` : ''
    },
    imageSrc () {
      return this.product && this.product.images.length ? this.product.images[0].j840 : ''
    },
    contactsLineTitle () {
      return this.isNotFound ? '' : 'Купите в Музее «Шоколадный Выборг»'
    }
  },
  async mounted () {
    await this.getDataPage(this.$route.params.slug)
  },
  async beforeRouteEnter (to, from, next) {
    await getProduct(to.params.slug)
    next()
  },
  async beforeRouteUpdate (to, from, next) {
    await getProduct(to.params.slug)
    await this.getDataPage(to.params.slug)
    next()
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'Error') {
      this.isNotFound = true
      next(false)
    }
    next()
  }
}
</script>
