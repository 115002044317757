<template lang="pug">
teleport(to="body", v-if="product" )
  component(:is="'script'", type="application/ld+json", v-html="jsonld" )
</template>

<script>
import { url } from '../../assets/scripts/variables'

export default {
  name: 'SchemaOrgProduct',
  props: {
    product: Object
  },
  data () {
    return {
      url
    }
  },
  computed: {
    productName () {
      return this.product.name ? this.product.name : ''
    },
    productImage () {
      return this.product.images && this.product.images.length ? this.product.images[0].j840 : ''
    },
    productDescription () {
      return this.product.description ? this.product.description : ''
    },
    productURL () {
      return `${this.url}${this.$route.path}`
    },
    productPrice () {
      return this.product.price_current ? this.product.price_current : ''
    },
    productWeight () {
      return this.product.weight ? this.product.weight : ''
    },
    jsonld () {
      return JSON.stringify({
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: this.productName,
        image: this.productImage,
        description: this.productDescription,
        url: this.productURL,
        weight: this.productWeight,
        offers: {
          '@type': 'Offer',
          priceCurrency: 'RUB',
          price: this.productPrice
        }
      })
    }
  }
}
</script>
