<template lang="pug">
.crumbs(:class="classMod ? `crumbs_${classMod}` : false")
  .crumbs__cont.cont
    ul.crumbs__list
      li.crumbs__item
        router-link.crumbs__link(:to="{name: 'Catalog'}") Каталог
      li.crumbs__item
        router-link.crumbs__link(:to="{ name: 'Category', params: { slug: slug } }") {{linkName}}
</template>

<script>
import '@/assets/styles/components/crumbs.sass'

export default {
  name: 'TheCrumbs',
  props: {
    slug: String,
    linkName: String,
    classMod: String
  }
}
</script>
